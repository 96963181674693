// const React = require('react');
import React from 'react';
import './style/Team.css'
import person from "../img/team/person.jpg";
import denis from '../img/team/denis.jpeg'
import sergey from '../img/team/sergey.JPG'
import ksusha from '../img/team/ksusha.JPG'
import yana from '../img/team/yana.JPG'
import katya from '../img/team/katya.jpeg'
import vlad from '../img/team/vlad.jpeg'
import vladimir from '../img/team/vladimir.JPG'
import yulia from '../img/team/yulya.jpeg'
import {t} from "i18next";


export default class Team extends React.Component {
        constructor(props) {
            super(props);
            this.state = {}
        }
    render()
    {
        let team = [
        {img: vladimir,  name: t("collective.1.name"), title: t("collective.1.title"), active: false, description: ""},
        {img: yana,  name: t("collective.2.name"), title: t("collective.2.title"), active: false},
        {img: person,  name: t("collective.3.name"), title: t("collective.3.title"), active: false},
        {img: denis, name: t("collective.4.name"), title: t("collective.4.title"), active: false},
        {img: person,  name: t("collective.5.name"), title: t("collective.5.title"), active: false},
        {img: person, name: t("collective.6.name"), title: t("collective.6.title"), active: false},
        {img: yulia,  name: t("collective.7.name"), title: t("collective.7.title"), active: false},
        {img: vlad,   name: t("collective.8.name"), title: t("collective.8.title"), active: false},
        {img: person,   name: t("collective.9.name"), title: t("collective.9.title"), active: false},
        {img: person,   name: t("collective.10.name"), title: t("collective.10.title"), active: false}]


        return (
/*            <div className='team-container'>
                  <div className='person_'>
                      <div className='person-back_'>
                        <a target="_blank" rel="noopener noreferrer" >
                                <img className='person-img_' index='' src={this.state.img} alt='фото' width='180px'/>
                        </a>
                      </div>
                        <div className='mini-header dark name'>{this.state.name}</div>
                        <div className='mini-header dark title-person_'>{this.state.title}</div>
                    </div>
                <div className='team'>
                    {this.state.clients.map((item, index) =>
                        <div className='person'  key={index} onClick={() => this.setState({img: item.img, name: item.name, title: item.title})}>
                            <a target="_blank" rel="noopener noreferrer" href={item.href}>
                                <img className='person-img' src={item.img} alt={item.uuid} width='60px'/>
                            </a>
                            <div className='person-description'>
                                <div className='mini-header dark name'>{item.name}</div>
                                <div className='mini-header dark title-person'>{item.title}</div>
                            </div>
                        </div> )}
                </div>
            </div>*/
            <div>
                <div className='team_'>
                    {team.map((item, index) =>
                        <div className='person__' key={index} onClick={() => this.setState({img: item.img, name: item.name, title: item.title})}>
                            <a target="_blank" rel="noopener noreferrer" href={item.href}>
                                <img className='person-img person-img_ ' src={item.img} alt={item.uuid} width='160px'/>
                            </a>
                            <div className='person-description_'>
                                <div className='mini-header dark name'>{item.name}</div>
                                <div className='mini-header dark title-person_'>{item.title}</div>
                                <div className=' description'>{item.description}</div>
                            </div>

                        </div> )}
                </div>
            </div>
        );
    }
}

