// const React = require('react');
import React from 'react';
import './style/NavBar.css'
import logo from "../logo.svg";
import {changeLanguage, t} from "i18next";


export default class NavBar extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        }
    }
    render()
    {
        let navbar = [{name: 'about', href: '/'},
            {name: 'projects', href: '/#projects'},
            {name: 'services', href: '/#services'},
            {name: 'clients', href: '/#clients'},
            {name: 'technology', href: '/#technology'},
            {name: 'team', href: '/#team'},
            {name: 'contacts', href: '/#contacts'}]
        return (
            <div>
                <div className={this.state.active ? 'darken' : 'none-darken'}></div>
                <div className='navbar-container'>
                    <div className='navbar container'>
                        <a href="/">
                            <img src={logo} alt="Logo" className='logo'/>
                        </a>
                        <div className='navbar-items'>
                            {navbar.map((item, index) => <a className='navbar-item' key={index}
                                                            href={item.href}>{t(item.name)}</a>)}
                        </div>
                        <div className="lng_btn_container">
                            <i className="fa fa-globe navbar-item"></i>
                            <button className="lng_btn" onClick={() => changeLanguage("en")}>EN</button>
                            <button className="lng_btn" onClick={() => changeLanguage("ru")}>RU</button>
                            {/*        <button className="lng_btn" onClick={() => changeLanguage("cn")}>CN</button>*/}
                        </div>
                        <a className='navbar-item' href="tel:+79216460099"><i className='fa fa-phone'></i>+7 921 646 00
                            99</a>
                    </div>
                    <div className='navbar-mobile'>
                        <a onClick={() => this.setState({active: !this.state.active})}><i
                            className='fa fa-bars burger'></i></a>

                        <img src={logo} alt="Logo" className='logo'/>
                        {this.state.active &&
                            <div className='navbar-items'>
                                {navbar.map((item, index) => <a className='navbar-item' key={index} href={item.href}
                                                                onClick={() => this.setState({active: !this.state.active})}>{t(item.name)}</a>)}
                                <a className='navbar-item' href="tel:+79216460099"><i className='fa fa-phone'></i>+7 921
                                    646 00 99</a>
                                <div className="lng_btn_container">
                                    <i className="fa fa-globe navbar-item"></i>
                                    <button className="lng_btn" onClick={() => changeLanguage("en")}>EN</button>
                                    <button className="lng_btn" onClick={() => changeLanguage("ru")}>RU</button>
                                    {/*   <button className="lng_btn" onClick={() => changeLanguage("cn")}>CN</button>*/}
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        );
    }
}



