import React, {Component} from 'react';
import "../style/Page.css"
import "../../App.css"
import acc_img from "../Projects/projects_img/acc_meter_img.png"
import acc1 from "../Projects/projects_img/acc1.png"
import acc2 from "../Projects/projects_img/acc2.png"
import acc3 from "../Projects/projects_img/acc3.png"
import acc4 from "../Projects/projects_img/acc4.png"
import acc5 from "../Projects/projects_img/acc5.png"
import AliceCarousel from "react-alice-carousel";
import {t} from "i18next";


class IntemsPIK extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openImage: false,
        }
    }
    render() {
        window.scrollTo(0, 0)
        let img = [acc3, acc2, acc5, acc4, acc1]
        return (
            <div>
                <div className="title_block wrapper page_wrapper">
                    <div className="container">
                        <div className="block_container header_block_container">
                            {/*<img className="page_main_img" src={acc_img} alt="accMeter"/>*/}
                            <div className="title_block_text">
                                <div className='header_page light'>{t("accMeter.header")}</div>
                                <div className="mini-header light">{t("accMeter.title")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='wrapper_white' id='contacts'>
                    <div className='container container-page'>
                        <div className="header_page">{t("accMeter.title_1")}</div>
                        <div className="main_text">{t("accMeter.description")}</div>
                        <div className="img_container">
                            {img.map((item, index) =>
                                    <img className="page_img" src={item} alt={img.index}/>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        ); 
    }
}

export default IntemsPIK;