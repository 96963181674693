// const React = require('react')
import React from 'react';
import NET from 'vanta/dist/vanta.net.min'
import './style/NetBackground.css'
// const Modal = require("./UI/Modal.jsx");
import Modal from "./UI/Modal.jsx";
import './../App.css'
import {t} from "i18next";


export default class NetBackground extends React.Component {
    constructor(props) {
        super(props)
        this.vantaRef = React.createRef()
        this.state = {
            isModal: false,
            ModalMessage: "",
        }
    }

    componentDidMount() {
        this.vantaEffect = NET({
            el: this.vantaRef.current,
            mouseControls: true,
            touchControls: true,
            gyroControls: true,
            minHeight: 200.00,
            minWidth: 200.00,
            scale: 1,
            scaleMobile: 1,
            points: this.props.points,
            maxDistance: this.props.maxDistance,
            spacing: this.props.spacing,
            showDots: true,
            color: "#5F86C4",
            backgroundColor: "#0B0D2F"
        })
    }
    componentWillUnmount() {
        if (this.vantaEffect) this.vantaEffect.destroy()
    }
    render() {
        return (
                <div ref={this.vantaRef} className=''>
                    <Modal
                        isVisible={this.state.isModal}
                        title="Modal Title"
                        content={this.state.ModalMessage}
                        onClose={() => this.setState({isModal: false})}
                    />
                    <div className='container '>
                        <div className= 'header-container' id='back'>
                            <div className='main_header'>{t("mainTitle")}</div>
                            <div className='mini-header'>{t("miniTitle")}</div>
                            <button className='main_btn' onClick={()=> this.setState({isModal: true, ModalMessage: 'sddsaas'})}>{t("mainBtn")}</button>
                        </div>
                    </div>
                    <div className='counters container' >
{/*                        <div className='line-counter'>
                            <div className='counter'>
                            <i className='counter-img fa fa-check-circle'/>
                            <div className='name-value'>
                                    <i className='counter-value'>20</i>
                                <i className='counter-name'>Завершенных проектов</i>
                            </div>
                        </div>
                        <div className='counter'>
                            <i className='counter-img fa fa-user-circle'/>
                            <div className='name-value'>
                                <i className='counter-value'>159</i>
                                <i className='counter-name'>Клиентов</i>
                            </div>
                        </div>
                        </div>
                        <div className='line-counter'>
                            <div className='counter'>
                            <i className='counter-img fa fa-cogs'/>
                            <div className='name-value'>
                                <i className='counter-value'>400</i>
                                <i className='counter-name'>Устройств</i>
                            </div>
                        </div>
                        <div className='counter'>
                            <i className='counter-img fa fa-code'/>
                            <div className='name-value'>
                                <i className='counter-value'>70900</i>
                                <i className='counter-name'>Строчек кода</i>
                            </div>
                        </div>
                        </div>*/}
                    </div>
            </div>
        )
    }
}

