// const React = require('react');
import React from 'react';
import './Modal.css'
import '../../App.css'
import Iframe from "react-iframe";
import {t} from "i18next";

const ModalSubmit = ({ isVisible = false, content, onClose, active }) => {
    const keydownHandler = ({ key }) => {
        switch (key) {
            case 'Escape':
                onClose();
                break;
            default:
        }
    };

    React.useEffect(() => {
        document.addEventListener('keydown', keydownHandler);
        return () => document.removeEventListener('keydown', keydownHandler);
    });

    return !isVisible ? null : (
        <div className="modal" onClick={onClose}>
            <div className="modal-dialog modal-dialog" onClick={e => e.stopPropagation()}>
                <div className="modal-body dark">
                    <div className='header header-modal'>{t("contacts")}</div>
                            <div className='mini-header dark'>{t("contact.phoneNumber")}</div>
                            <div className='mini-header dark'>{t("contact.eMail")}</div>
                            <div className='mini-header dark'>{t("contact.address")}</div>
                            <div className='mini-header dark'>{t("contact.timetable")}</div>
                    <div className='map'><a
                        href="https://yandex.ru/maps/2/saint-petersburg/?utm_medium=mapframe&utm_source=maps"
                        className='map-text'>Санкт‑Петербург</a><a
                        href="https://yandex.ru/maps/2/saint-petersburg/house/kantemirovskaya_ulitsa_12/Z0kYdQRhSEYOQFtjfXV5cnVqbQ==/?ll=30.334704%2C59.984747&utm_medium=mapframe&utm_source=maps&z=16.74"
                        className='map-address'>Кантемировская улица, 12 —
                        Яндекс Карты</a>
                        <Iframe url="https://yandex.ru/map-widget/v1/-/CCUjV4TPgC" width={window.innerWidth >= 920 ? "560 " : "300"} height={window.innerWidth >= 920 ? "400 " : "200"}
                                frameBorder="0" allowFullScreen="true" style="position:relative;">
                        </Iframe>
                    </div>
                    {/*<div className='header header-modal'>Заполните необходимые поля</div>
                    <div className='mini-header header-mini-modal dark'>Ваше имя</div>
                    <input className='myInput' type='text'/>
                    <div className='mini-header dark header-mini-modal'>Ваше телефон</div>
                    <input className='myInput' type='tel'/>
                    <div className='mini-header dark header-mini-modal'>E-mail</div>
                    <input className='myInput' type='email'/>
                    <div className='mini-header dark header-mini-modal'>Опишите задачу</div>
                    <textarea className='myText'/>
                    <input className='myInput' type='file'/>*/}
                    <div className='submit'>
                <span className='modal-close' onClick={onClose}>
                    <button className='modal-btn' onClick={active}>{t("closeBtn")}</button>
                 </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalSubmit;