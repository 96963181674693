// const React = require('react');
import React from 'react';
import './style/Projects.css'
/*import Carousel from 'carousel-react-rcdev'*/
import AliceCarousel from 'react-alice-carousel';
import project_1 from '../img/projects/kubiki_project.png'
import project_2 from '../img/projects/pik_project.png'
import project_3 from '../img/projects/robot_project.png'
import project_4 from '../img/projects/plata_project.png'
import project_5 from '../img/projects/simargle_project.png'
import project_6 from '../img/projects/fdt_project.png'
import project_7 from '../img/projects/pay_sistem.png'
import project_8 from '../img/projects/hot_sistem.png'
import "react-alice-carousel/lib/alice-carousel.css";
import {Link} from "react-router-dom";
import {t} from "i18next";

export default class Projects extends React.Component {
    render() {
        let projects = [
            {id: 1, name: t('project.1.name'), title: t('project.1.title'), img: project_1, href: "kubiki"},
            {id: 2, name: t('project.2.name'), title: t('project.2.title'), img: project_2, href: "IntemsPIK"},
            // {id: 3, name: t('project.3.name'), title: t('project.3.title'), img: project_3, href: "automatedPlatform"},
            {id: 4, name: t('project.4.name'), title: t('project.4.title'), img: project_6, href: "airPortal"},
            {id: 5, name: t('project.5.name'), title: t('project.5.title'), img: project_4, href: "accelerationMeter"},
            {id: 6, name: t('project.6.name'), title: t('project.6.title'), img: project_5, href: "craneComplex"},
            {id: 7, name: t('project.7.name'), title: t('project.7.title'), img: project_7, href:"paySystem"},
            {id: 8, name: t('project.8.name'), title: t('project.8.title'), img: project_8, href:"hotLab"}
            ]
        return (
            <div className='projects-container'>
                <div className='projects'>
                    <AliceCarousel controlsStrategy="responsive, alternate" responsive={{
                        0: {
                            items: 1,
                        },
                        780: {
                            items: 2,
                            itemsFit: 'fill',
                        },
                        1280: {
                            items: 3,
                            itemsFit: 'fill',
                        },
                    }}>
                    {projects.map((item,index)=>
                        <Link to={item.href}>
                        <div  key={index}>
                            <div className='project'>
                                <div className='rectangle'>
                                    <img className='project-img' src={item.img} alt='project'/>
                                    <div className='title-project'>{item.title}</div>
                                </div>
                                <div className='name-project'>{item.name}</div>
                            </div>

                        </div>
                        </Link>
                    )}
                    </AliceCarousel>
                </div>
            </div>
        );
    }
}

