import React, {Component} from 'react';
import "../style/Page.css"
import "../../App.css"
import lab_1 from "./projects_img/lab_1.png";
import lab_2 from "./projects_img/lab_2.png";
import lab_3 from "./projects_img/lab_3.png";
import lab_4 from "./projects_img/lab_4.png";
import {t} from "i18next";

class IntemsPIK extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openImage: false,
        }
    }
    render() {
        window.scrollTo(0, 0)
        return (
            <div>
                <div className="title_block wrapper page_wrapper">
                    <div className="container">
                        <div className="block_container header_block_container">
                            <div className="title_block_text">
                                <div className='header_page light'>{t("hotLab.header")}</div>
                                <div className="mini-header light">{t("hotLab.title")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='wrapper_white' id='contacts'>
                    <div className='container container-page'>
                        <div className="header_page">{t("hotLab.title_1.name")}</div>
                        <div className="main_text text_flex">{t("hotLab.title_1.text")}</div>
                        <img className="page_img page_img_png" src={lab_1} alt="lab1"/>
                        <div className="header_page">{t("hotLab.title_2.name")}</div>
                        <div className="main_text">{t("hotLab.title_2.text")}</div>
                        <div className="img_container">
                            <img className="page_img" src={lab_2} alt="lab2"/>
                            <img className="page_img" src={lab_3} alt="lab3"/>
                            <img className="page_img" src={lab_4} alt="lab4"/>
                        </div>
                    </div>
                </div>
            </div>
        ); 
    }
}

export default IntemsPIK;