import React, {Component} from 'react';
import "../style/Page.css"
import "../../App.css"
import platform_main from "../Projects/projects_img/platform_img.png"
import platform_1 from "../Projects/projects_img/platform_1.png"
import platform_2 from "../Projects/projects_img/platform_2.png"
import platform_pult from "../Projects/projects_img/platform_pult.png"
import platform_4 from "../Projects/projects_img/platform_4.png"
import {t} from "i18next";


class IntemsPIK extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openImage: false,
        }
    }
    render() {
        window.scrollTo(0, 0)
        return (
            <div>
                <div className="title_block wrapper page_wrapper">
                    <div className="container">
                        <div className="block_container header_block_container">
                            {/* <img className="page_main_img" src={platform_main} alt="platform"/>*/}
                            <div className="title_block_text">
                                <div className='header_page light'>{t("automaticPlatform.header")}</div>
                                <div className="mini-header light">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='wrapper_white' id='contacts'>
                    <div className='container container-page'>
                        <div className="header_page">{t("automaticPlatform.title_1.name")}</div>
                        <div className="img_container">
                            <div className="main_text text_flex">
                                <ul>
                                    <li>{t("automaticPlatform.title_1.1")}</li>
                                    <li>{t("automaticPlatform.title_1.2")}</li>
                                    <li>{t("automaticPlatform.title_1.3")}</li>
                                    <li>{t("automaticPlatform.title_1.4")}</li>
                                    <li>{t("automaticPlatform.title_1.5")}</li>
                                    <li>{t("automaticPlatform.title_1.6")}</li>
                                </ul>
                            </div>
                            <img className="" width="200" height="auto" src={platform_4} alt="platform_4"/>
                        </div>
                        <div className="header_page">{t("automaticPlatform.title_2.name")}</div>
                        <div className="main_text">
                            <div className="block_container">
                                <div>
                                    <div className="header_block">{t("automaticPlatform.title_2.1.header")}</div>
                                    <div className="text_block">{t("automaticPlatform.title_2.1.body")}</div>
                                </div>
                                <div>
                                    <div className="header_block">{t("automaticPlatform.title_2.2.header")}</div>
                                    <div className="text_block">{t("automaticPlatform.title_2.2.body")}</div>
                                </div>
                                <div>
                                    <div className="header_block">{t("automaticPlatform.title_2.3.header")}</div>
                                    <div className="text_block">{t("automaticPlatform.title_2.3.body")}</div>
                                </div>
                                <div>
                                    <div className="header_block">{t("automaticPlatform.title_2.4.header")}</div>
                                    <div className="text_block">{t("automaticPlatform.title_2.4.body")}</div>
                                </div>
                                <div>
                                    <div className="header_block">{t("automaticPlatform.title_2.5.header")}</div>
                                    <div className="text_block">{t("automaticPlatform.title_2.5.body")}</div>
                                </div>
                            </div>
                        </div>
                        <div className="header_page">{t("automaticPlatform.title_3.name")}</div>
                        <div className="main_text">{t("automaticPlatform.title_3.text")}
                            <div className="img_container">
                                <img className="page_img page_img_png" src={platform_main} alt="platform_"/>
                                <img className="page_img page_img_png" src={platform_1} alt="platform_1"/>
                            </div>
                        </div>
                        <div className="header_page">{t("automaticPlatform.title_4.name")}</div>
                        <div className="main_text">{t("automaticPlatform.title_4.text")}
                            <div className="img_container">
                                <img className="page_img page_img_png" src={platform_2} alt="platform_2"/>
                            </div>
                        </div>
                        <div className="header_page">{t("automaticPlatform.title_5.name")}</div>
                        <div className="main_text">{t("automaticPlatform.title_5.text")}
                            <div className="img_container">
                                <img className="page_img" src={platform_pult} alt="platform_3"/>
                            </div>
                        </div>
                        <div className="header_page">{t("automaticPlatform.title_6.name")}</div>
                    </div>
                </div>
            </div>
        ); 
    }
}

export default IntemsPIK;