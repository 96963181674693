// const React = require('react');
import React from 'react';
import './style/Clients.css'
import './UI/Carousel.css'
import logo_1 from "../img/clients/signal.svg";
import logo_2 from "../img/clients/mir.png";
import logo_3 from "../img/clients/semargl.svg";
import logo_4 from "../img/clients/vendex.png";
import logo_5 from "../img/clients/lpm.svg";
import logo_6 from "../img/clients/fdt.png";
import logo_7 from "../img/clients/imis.jpg";
import logo_8 from "../img/clients/kubiki.png";
import logo_9 from "../img/clients/leti.png";
import logo_10 from "../img/clients/ITMO.png";
import logo_11 from "../img/clients/mechnikova.png";

export default class Clients extends React.Component {

    render() {
    let clients = [{img: logo_1, href: 'https://ntosignal.ru/'},
        {img: logo_2, href: 'https://mir.dev/'},
        {img: logo_3, href: 'https://semargl.pro/'},
        {img: logo_4, href: 'http://vendexretail.ru/'},
        {img: logo_5, href: 'http://complex-light.ru/'},
        {img: logo_6, href: 'https://www.fdt.link/'},
        {img: logo_7, href: 'https://www.imismoto.ru/imismoto'},
        {img: logo_8, href: 'https://kubiky.com/'},
        {img: logo_9, href: 'https://etu.ru/'},
        {img: logo_10, href: 'https://itmo.ru/'},
        {img: logo_11, href: 'https://szgmu.ru/rus/s/17/o_szgmu.html'}, ]
        return (
            <div className='clients-container'>
                <div className='clients' >
                    {clients.map((item, index)=> <a  key={index} target="_blank" rel="noopener noreferrer" href={item.href}>
                            <div className='client' >
                                <img className='client-img' src={item.img} alt={item.uuid} width='160px'/>
                            </div>
                    </a>
                        )}
                </div>
            </div>
        );
    }
}

