import React, {Component} from 'react';
import "../style/Page.css"
import "../../App.css"
import {t} from "i18next";


class IntemsPIK extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openImage: false,
        }
    }
    render() {
        window.scrollTo(0, 0)
        return (
            <div>
                <div className="title_block wrapper page_wrapper">
                    <div className="container">
                        <div className="block_container header_block_container">
                            <div className="title_block_text">
                                <div className='header_page light'></div>
                                <div className="mini-header light">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='wrapper_white' id='contacts'>
                    <div className='container container-page'>
                        <div className="main_text">{t("processPage")}</div>
                    </div>
                </div>
            </div>
        ); 
    }
}

export default IntemsPIK;