import React from 'react';
import Current from "./Current.jsx";
import Projects from "./Projects.jsx";
import Services from "./Services.jsx";
import Clients from "./Clients.jsx";
import Technology from "./Technology.jsx";
import Team from "./Team.jsx";
import Iframe from "react-iframe";
import {t} from "i18next";

const Main = () => {
    return (
        <div>
            <div className='wrapper current_wrapper'>
                <div className='container'>
                     {/*<div className='header light'>Продукты</div>*/}
                    <Current/>
                </div>
            </div>
            <div className='wrapper_white' id='projects' >
                <div className='container'>
                    <div className='header dark'>{t("projects")}</div>
                    <Projects/>
                </div>
            </div>
            <div className='wrapper' id='services'>
                <div className='container'>
                    <div className='header light'>{t("services")}</div>
                    <div className='mini-header light'>{t("service.title")}</div>
                    <Services />
                </div>
            </div>
            <div className='wrapper_white' id='clients'>
                <div className='container'>
                    <div className='header dark'>{t("clients")}</div>
                    <Clients/>
                </div>
            </div>
            <div className='wrapper_dark' id='technology'>
                <div className='container'>
                    <div className='header light'>{t("technology")}</div>
                    <Technology/>
                </div>
            </div>
            <div className='wrapper_white' id='team'>
                <div className='container'>
                    <div className='header dark'>{t("team")}</div>
                    <Team/>
                </div>
            </div>
            {/*<div className='wrapper wrapper_1' id='pik'>
              <div className='container'>
                  <div className='header light'>IntemsLab-ПИК</div>
                  <div className='mini-header'>Прибор контрольно-измерительный. Сетевое устройство для измерения параметров электросети, а так же контроля состояния датчиков подключеных к нему.</div>
              </div>
          </div>*/}
            <div className='wrapper_white' id='contacts'>
                <div className='container'>
                    <div className='header dark'>{t("contacts")}</div>
                    <div className='contacts'>
                        <div className='contacts-col'>
                            <div className='map'><a
                                href="https://yandex.ru/maps/2/saint-petersburg/?utm_medium=mapframe&utm_source=maps"
                                className='map-text'>Санкт‑Петербург</a><a
                                href="https://yandex.ru/maps/2/saint-petersburg/house/kantemirovskaya_ulitsa_12/Z0kYdQRhSEYOQFtjfXV5cnVqbQ==/?ll=30.334704%2C59.984747&utm_medium=mapframe&utm_source=maps&z=16.74"
                                className='map-address'>Кантемировская улица, 12 —
                                Яндекс Карты</a>
                                <Iframe url="https://yandex.ru/map-widget/v1/-/CCUjV4TPgC" width={window.innerWidth >= 920 ? "560 " : "300"} height={window.innerWidth >= 920 ? "400 " : "200"}
                                        frameBorder="0" allowFullScreen="true" style="position:relative;"></Iframe>
                            </div>
                        </div>
                        <div className='contacts-col'>
                            <div className='mini-header dark'>{t("contact.timetable")}</div>
                            <div className='mini-header dark'>{t("contact.phoneNumber")}</div>
                            <div className='mini-header dark'>{t("contact.eMail")}</div>
                            <div className='mini-header dark'>{t("contact.address")}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;