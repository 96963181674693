import React, {Component} from 'react';
import "../style/Page.css"
import "../../App.css"
import air_mono_img from "../Projects/projects_img/air_img.png"
import air_1 from "../Projects/projects_img/air_1.png"
import air_2 from "../Projects/projects_img/air_2.png"
import {t} from "i18next";

class IntemsPIK extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openImage: false,
        }
    }
    render() {
        window.scrollTo(0, 0)
        return (
            <div>
                <div className="title_block wrapper page_wrapper">
                    <div className="container">
                        <div className="block_container header_block_container">
                            <img className="page_main_img" src={air_mono_img} alt="airportal"/>
                            <div className="title_block_text">
                                <div className='header_page light'>{t("airPortal.header")}</div>
                                <div className="mini-header light">{t("airPortal.title")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='wrapper_white' id='contacts'>
                    <div className='container container-page'>
                        <div className="header_page ">{t("airPortal.title_1.name")}</div>
                        <div className="main_text">
                           <ul>
                               <li>{t("airPortal.title_1.1")}</li>
                               <li>{t("airPortal.title_1.2")}</li>
                               <li>{t("airPortal.title_1.3")}</li>
                               <li>{t("airPortal.title_1.4")}</li>
                               <li>{t("airPortal.title_1.5")}</li>
                           </ul>
                        </div>
                        <div className="header_page ">{t("airPortal.title_2")}</div>
                        <div className="block_container">
                            <img className="page_img" src={air_1} alt="airportal1"/>
                            <img className="page_img" src={air_2} alt="airportal2"/>
                        </div>
                    </div>
                </div>
            </div>
        ); 
    }
}

export default IntemsPIK;