import React, {Component} from 'react';
import "../style/Page.css"
import "../../App.css"
import pay_1 from "./projects_img/pay_1.svg";
import pay_2 from "./projects_img/pay_2.png";
import {t} from "i18next";


class IntemsPIK extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openImage: false,
        }
    }
    render() {
        window.scrollTo(0, 0)
        return (
            <div>
                <div className="title_block wrapper page_wrapper">
                    <div className="container">
                        <div className="block_container header_block_container">
                            <div className="title_block_text">
                                <div className='header_page light'>{t("paySystem.header")}</div>
                                <div className="mini-header light">{t("paySystem.title")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='wrapper_white' id='contacts'>
                    <div className='container container-page'>
                        <div className="header_page">{t("paySystem.title_1.name")}</div>
                        <div className="main_text">
                            <ul>
                                <li>{t("paySystem.title_1.1")}</li>
                                <li>{t("paySystem.title_1.2")}</li>
                                <li>{t("paySystem.title_1.3")}</li>
                                <li>{t("paySystem.title_1.4")}</li>
                            </ul>
                        </div>
                        <div className="header_page">{t("paySystem.title_2.name")}</div>
                        <div className="main_text">
                            {t("paySystem.title_2.1")}<a href="http://vendexretail.ru/">{t("paySystem.title_2.a")}</a>{t("paySystem.title_2.2")}
                        </div>
                        <div className="img_container">
                            <img className="page_img page_img_png" src={pay_1} alt="pay1"/>
                            <img className="page_img page_img_png" src={pay_2} alt="pay2"/>
                        </div>
                    </div>

                </div>
            </div>
        ); 
    }
}

export default IntemsPIK;