// const React = require('react');
import React from 'react';

// const ReactDOM = require('react-dom/client');
import ReactDOM from 'react-dom/client';
import './index.css';
// const App = require('./App.jsx');
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';
import './i18n.jsx';
import { Suspense } from 'react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Suspense fallback={<div>Loading...</div>}>
          <App/>
      </Suspense>
  </React.StrictMode>
);