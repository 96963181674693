// const React = require('react');
import React from 'react';
import './style/Services.css'
import service_1 from "../img/service/serv_1.svg";
import service_2 from "../img/service/serv_2.svg";
import service_3 from "../img/service/serv_3.svg";
import service_4 from "../img/service/serv_4.svg";
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from 'react-alice-carousel';
import {t} from "i18next";

export default class Services extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let services = [
            {name: t("service.1.name"), img: service_1, description: t("service.1.title")},
            {name: t("service.2.name"), img: service_2, description: t("service.2.title")},
            {name: t("service.3.name"), img: service_3, description: t("service.3.title")},
            {name: t("service.4.name"), img: service_4, description: t("service.4.title")}]
        return (
            <div className='services-container'>
                <div className='services'>
                    <AliceCarousel responsive={{
                        0: {
                            items: 1,
                        },
                        780: {
                            items: 2,
                            itemsFit: 'fill',
                        },
                        1280: {
                            items: 3,
                            itemsFit: 'fill',
                        },
                        1300:{
                            items: 4,
                            itemsFit: 'fill',
                        }
                    }}>
                    {services.map((item, index)=>
                        <div className='service'  key={index}>
                            <div className='circle'>
                                <img className='service-img' src={item.img} alt={item.uuid} width='80px'/>
                                <div className='service-description'>{item.description}</div>
                            </div>
                        <div className='title'>{item.name}</div>
                    </div>)}
                    </AliceCarousel>
                </div>
            </div>
        );
    }
}

