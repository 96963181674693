// const React = require('react');
import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import './style/Current.css'
import "react-alice-carousel/lib/alice-carousel.css";
import news_1 from '../img/current/PLC.png'
import {Link} from "react-router-dom";
import {t} from "i18next";

export default class Current extends React.Component {
    render() {
        let news = [
            {img: news_1, name: t("current.1.name"), title: t("current.1.title"), href: "IntemsPlc"}
        ]
        return (
            <div className='current-container'>
                <div className='currents'>
                    <AliceCarousel autoPlay="true" autoPlayInterval="4000" controlsStrategy="alternate">
                    {/*<AliceCarousel>*/}
                    {news.map((item, index) =>
                        <div key={index}>
                            <div className='current'>

                                    <Link className='rectangle_' to={item.href}>
                                        <img className='current-img' src={item.img} alt='current' width="40%"
                                             height="100%"/>
                                        <div>
                                            <div className='name-current'>{item.name}</div>
                                            <div className='title-current'>{item.title}</div>
                                        </div>
                                    </Link>

                            </div>
                        </div>
                    )}
                    </AliceCarousel>
                    {/* </AliceCarousel>*/}
                </div>
            </div>
        );
    }
}

