import React, {Component} from 'react';
import "../style/Page.css"
import "../../App.css"
import kubiki_logo from "../Projects/projects_img/kubiki_logo.png"
import kubiki_group from "../Projects/projects_img/kubiki_group.png"
import {t} from "i18next";

class IntemsPIK extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openImage: false,
        }
    }
    render() {
        window.scrollTo(0, 0)
        return (
            <div>
                <div className="title_block wrapper page_wrapper">
                    <div className="container">
                        <div className="block_container header_block_container">
                            <img className="page_main_img" src={kubiki_logo} alt="kubiki"/>
                            <div className="title_block_text">
                                <div className='header_page light'>{t("kubiki.header")}</div>
                                <div className="mini-header light">{t("kubiki.title")}
                                    <br/>{t("kubiki.aTitle")}<a className="a_page" target="_blank"
                                                                href="https://kubiky.com/">"Кубики"</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='wrapper_white' id='contacts'>
                    <div className='container container-page'>
                        <div className="header_page_center header_page">{t("kubiki.title_1.name")}</div>
                        <img className="page_main_img mono_img" src={kubiki_group} alt="kubiki"/>
                        <div className="main_text">
                            <div className="block_container">
                                <div>
                                    <div className="header_block">{t("kubiki.title_1.1.header")}</div>
                                    <div className="text_block">
                                        <ul>
                                            <li>{t("kubiki.title_1.1.body.1")}</li>
                                            <li>{t("kubiki.title_1.1.body.2")}</li>
                                            <li>{t("kubiki.title_1.1.body.3")}</li>
                                            <li>{t("kubiki.title_1.1.body.4")}</li>
                                            <li>{t("kubiki.title_1.1.body.5")}</li>
                                        </ul>
                                    </div>
                                    </div>
                                    <div>
                                        <div className="header_block">{t("kubiki.title_1.2.header")}</div>
                                        <div className="text_block">
                                            <ul>
                                                <li>{t("kubiki.title_1.2.body.1")}</li>
                                                <li>{t("kubiki.title_1.2.body.2")}</li>
                                                <li>{t("kubiki.title_1.2.body.3")}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="header_block">{t("kubiki.title_1.3.header")}</div>
                                        <div className="text_block">
                                            <ul>
                                                <li>{t("kubiki.title_1.3.body.1")}</li>
                                            </ul>
                                        </div>
                                    </div>


                                </div>
                        </div>
                        <div className="header_page_center header_page">{t("kubiki.title_2.name")}</div>
                        <div className="main_text">{t("kubiki.title_2.text")}</div>
                        <div className="header_page_center header_page">{t("kubiki.title_3.name")}</div>
                        <div className="main_text">{t("kubiki.title_3.text")}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default IntemsPIK;