import './App.css';
import NavBar from "./components/NavBar.jsx";
import NetBackground from "./components/NetBackground.jsx";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, {useState} from "react";
import Main from "./components/Main.jsx";
import IntemsPlc from "./components/IntemsPlc.jsx";
import Kubiki from "./components/Projects/Kubiki.jsx";
import IntemsPIK from "./components/Projects/IntemsPIK.jsx";
import AirPortal from "./components/Projects/AirPortal.jsx";
import AccelerationMeter from "./components/Projects/AccelerationMeter.jsx";
import AutomatedPlatform from "./components/Projects/AutomatedPlatform.jsx";
import CraneComplex from "./components/Projects/CraneComplex.jsx";
import PaySystem from "./components/Projects/PaySystem.jsx";
import HotLab from "./components/Projects/HotLab.jsx";
import {useTranslation} from "react-i18next";
import './i18n.jsx';
import ScrollButton from "./components/UI/ScrollButton.jsx";



function App (){
    const { t, i18n } = useTranslation();
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

        return (
        <div className="App" >
            <div id="my-background" className=''>
                <NavBar/>
            </div>
          <div>
              <ScrollButton />
          {/*<a className='angle-btn' href=""><i className='fa fa-angle-up angle-up'></i></a>*/}
              <BrowserRouter >
                  <Routes>
                      <Route  path="" element={<div>
                          {window.innerWidth >= 1280 && <NetBackground points='15 ' maxDistance='25' spacing='15'/>}
                          {window.innerWidth < 1280 && window.innerWidth >= 920 && <NetBackground points='20' maxDistance='25' spacing='20'/>}
                          {window.innerWidth <= 920 && <NetBackground points='20' maxDistance='30' spacing='30'/>}
                          <Main/>
                      </div>}/>
                      <Route  path="/IntemsPlc" element={<IntemsPlc/>}/>
                      <Route  path="/kubiki" element={<Kubiki/>}/>
                      <Route  path="/IntemsPIK" element={<IntemsPIK/>}/>
                      <Route  path="/airPortal" element={<AirPortal/>}/>
                      <Route  path="/accelerationMeter" element={<AccelerationMeter/>}/>
                      <Route  path="/automatedPlatform" element={<AutomatedPlatform/>}/>
                      <Route  path="/craneComplex" element={<CraneComplex/>}/>
                      <Route  path="/paySystem" element={<PaySystem/>}/>
                      <Route  path="/hotLab" element={<HotLab/>}/>
                      <Route  path="/" element={<Main/>}/>
                    {/*  <Route  path="datetime" element={<Layout content={<DateTime/>}/>} />
                      <Route  path="safety" element={<Layout content={<Safety />}/>} />
                      <Route  path="*" element={<Layout content={<Main/>}/>} />*/}
                  </Routes>
              </BrowserRouter>
          </div>
          <footer>

          </footer>
        </div>
  );
}
export default App;