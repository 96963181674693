// const React = require('react');
import React from 'react';
import './style/Technology.css'
import i18next from "i18next";
import "i18next";
import tech_1 from "../img/technology/technology_pc_ru.png"
import tech_2 from "../img/technology/technology_mob_ru.png"
import tech_3 from "../img/technology/technology_pc_en.png"
import tech_4 from "../img/technology/technology_mob_en.png"

export default class Technology extends React.Component {
    render() {
        if (i18next.language === "ru")
        {
            return (
                <div className='tech-img'>
                    <img src={window.innerWidth >= 920 ? tech_1 : tech_2} alt='technology' width='100%'/>
                </div>
            );
        }
        if (i18next.language === "en")
        {
            return (
                <div className='tech-img'>
                    <img src={window.innerWidth >= 920 ? tech_3 : tech_4} alt='technology' width='100%'/>
                </div>
            );
        }
    }
}

