import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';
import translationCN from './locales/cn/translation.json';

const resources = {
    en: {
        translation: translationEN
    },
    ru: {
        translation: translationRU
    },
    cn: {
        translation: translationCN
    }
};
i18n
    // Подключение бэкенда i18next
    .use(Backend)
    // Автоматическое определение языка
    .use(LanguageDetector)
    // модуль инициализации
    .use (initReactI18next)
    .init({
        resources,
        // Стандартный язык
        fallbackLng: 'ru',
        debug: true,
        // Распознавание и кэширование языковых кук
        detection: {
            order: ['queryString', 'cookie'],
            cache: ['cookie']
        },
        interpolation: {
            escapeValue: false
        },
        key: resources,
        lng: localStorage.getItem('i18nextLng') || 'ru'
    })

export default i18n;